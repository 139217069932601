import React from 'react'
import Layout from '../components/_App/Layout'
import NavbarTwo from '../components/_App/NavbarTwo'
import Seo from '../components/_App/Seo'
import PageBanner from '../components/Common/PageBanner'
import Services from '../components/Common/Services'
import ServiceSlider from '../components/Common/ServiceSlider'
import WhyOnlyThree from '../components/Common/WhyOnlyThree'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const ServicesPage = () => {
    return (
        <Layout>
            <Seo title="Services" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Our Services" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Our Services" 
            />

           <section className="service pt-70 pb-100">
                <div className="container">
                    <Services />
                </div>
            </section>

            <div className="bg-f8f8f8">
                <ServiceSlider />
            </div>

            <WhyOnlyThree />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default ServicesPage
